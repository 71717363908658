import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import ThemeStore from '../../context/ThemeStore';
import Theme from '../Theme';
import Header from '../Header';
import Footer from '../Footer/Footer';
import SEO from '../SEO';
import Navigation from '../Navigation';
import LayoutContext from '../../context/LayoutContext';
import { changeLocale, changeIsMobile } from '../../state/reducers/common';
import CookiesBar from '../CookiesBar';
import SlideMenu from '../SlideMenu';
import Breadcrumb from '../Breadcrumb';

const Layout = ({
  children,
  fullwidth,
  padding,
  seoSettings,
  hasBreadcrumb,
  breadcrumb,
}) => {
  const layoutProps = useContext(LayoutContext);
  let mainClass = [];
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });
  const { locale, isMobileView } = useSelector((state) => state.common);
  const gender = layoutProps.pageContext.gender;

  if (fullwidth) {
    mainClass.push('full-width');
  } else {
    mainClass.push('container-width');

    if (padding) {
      mainClass.push('content-padding');
    }
  }

  if (isTabletOrMobile !== isMobileView) {
    dispatch(changeIsMobile(isTabletOrMobile));
  }

  useEffect(() => {
    if (locale !== layoutProps.pageContext.locale) {
      dispatch(changeLocale(layoutProps.pageContext.locale));
    }
  }, []);

  return (
    <>
      <ThemeStore>
        <Theme>
          <SEO {...seoSettings} />
          <SlideMenu hasSearch={gender === 'men' ? false : true} />
          <Header gender={gender} />
          {!isMobileView && <Navigation />}
          <main className={mainClass.join(' ')}>
            {hasBreadcrumb && !isMobileView && (
              <Breadcrumb
                customList={breadcrumb}
                defaultList={layoutProps.pageContext}
              />
            )}
            {children}
          </main>
          <Footer gender={gender} />
          <CookiesBar />
        </Theme>
      </ThemeStore>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  fullwidth: PropTypes.bool,
  padding: PropTypes.bool,
  hasBreadcrumb: PropTypes.bool,
};

Layout.defaultProps = {
  fullwidth: false,
  padding: true,
  hasBreadcrumb: true,
  breadcrumb: [],
};
