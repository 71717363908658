import React, { useContext, useEffect } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ThemeContext } from '../../context/ThemeStore';
import { css } from 'styled-components';

const themes = {
  name: 'women',
  background: '#ffffff',
  headerBgColor: '#313131',
  primaryColor: '#313131',
  textColor: '#000000',
  headerColor: '#0b72b5',
  descColor: '#8397a8',
  activeColor: '#313131',
  // Form
  labelColor: '#0b72b5',
  formBorder: '1px solid #c4d1d9',
  submitBgColor: '#116787',
  submitHoverBgColor: '#5ba0b7',
  fombBgColor: 'linear-gradient(to bottom, #ebf4fb 33%, #f6fcfc 100%)',
  // navigation
  navContainer: css`
      border-bottom: 1px solid #313131;
    `,
  button: {
    bgColor: '#3471B0',
    bgHoverColor: '#fff',
  },
  // Products
  products: {
    nameColor: '#0b72b5',
    nameMinHeihgt: '100px',
    descMinHeihgt: '95px',
    border: '1px solid #e0e0e0',
  },
  prodcut: {
    nameColor: '#004b86',
    descColor: '#8397a8',
    panelHeaderBg: 'linear-gradient(#ffffff, #ececec)',
    panelContentBg: 'linear-gradient(#ffffff, #ececec)',
    panelContentColor: '#666666',
    border: '1px solid #c0c0c0',
    arrowColor: '#c0c0c0',
  },
  relatedProduct: {
    containerSize: '85%',
    titleColor: '#647178',
    boxSize: '245px',
    nameColor: '#0b72b5',
    buttonColor: 'white',
    buttonHoverColor: 'white',
    buttonBg: '#3471B0',
    buttonHoverBg: '#3471B0',
  },
  slideMenu: {
    bgColor: '#313131',
    activeColor: '#ffffff',
    activeBgColor: '#116787',
  },
  navigation: {
    tabColor: '#ffffff',
    tabActiveColor: '#116787',
    tabBg: '#116787',
    tabActiveBg: '#ffffff',
    titleColor: '#0b72b5',
    buttonColor: '#fff',
    buttonBg: '#3471B0',
    buttonHoverBg: '#3471B0',
  },
  home: {
    subCollapseActiveBg: '#176987;',
    subContentBg: '#ffffff',
    subCollapseActiveColor: '#ffffff',
    subContentColor: '#00447b',
  },
};

const GlobalStyle = createGlobalStyle`
  body{
     background:${(props) => props.theme.background};
  }

  h1 {
    font-size: 40px;
    text-transform: uppercase;
    color: ${(props) => props.theme.headerColor};
    font-family: "微軟正黑體", sans-serif;
    font-weight: bold;

    &:lang(en) {
      font-family: 'futural', 'futurastd', 'futurastd_IE', Arial, Helvetica, sans-serif;
      font-weight: normal;
    }
  }
`;

const Theme = ({ children, gender }) => {
  const { theme, swithTheme } = useContext(ThemeContext);
  useEffect(() => {
    if (theme !== gender) {
      swithTheme(gender);
    }
  }, []);

  return (
    <ThemeProvider theme={themes}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
