import styled, { css } from 'styled-components';
import { mediaQueries } from '../../utils/mediaQueries';
import Link from '../Link';

export const Container = styled.div`
  ${(props) => css`background-color: ${props.theme.primaryColor}`}
`;

export const Box = styled.div`
  max-width: 23%;
  flex: 0 0 23%;
`;

export const BoxContent = styled.div`
  width: 70%;
  margin: 0 auto;
`;

export const Header = styled.div`
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  font-size: '18px';
  color: ${() => ('#ffffff')};
  font-weight: bold;
  text-transform: 'capitalize';
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid;
  border-color: ${() =>'#ffffff'};
`;

export const FooterLink = styled(Link)`
  color: ${() => ('#ffffff')};
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.headerColor};
    text-decoration: none;
  }
`;

export const FooterHref = styled.a`
  color: ${() => ('#ffffff')};
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.headerColor};
    text-decoration: none;
  }
`;

export const BottomContainer = styled.div`
  ${mediaQueries('md')} {
    padding: 10px;
  }
`;

export const BottonLinks = styled.div`
  a {
    margin-right: 10px;
    display: inline-block;
    text-align: left;

    ${mediaQueries('md')} {
      margin-right: 5px;

      &:not(:last-child)::after {
        content: '/';
        padding-left: 5px;
      }
    }
  }
`;

export const FooterSubLink = styled(Link)`
  color: ${() => ('#535355')};
  transition: color 0.3s;
  font-size: ${() => ('13px')};

  @media screen and (min-width: 768px) {
    &:not(:last-child) {
      ${() => css`
          margin-right: 0.5rem;
          padding-right: 0.5rem;
          border-right: 1px solid #878787;
        `}
    }
  }

  &:hover {
    color: ${(props) => props.theme.activeColor};
    text-decoration: none;
  }
`;

export const FooterCookieLink = styled.a`
  color: ${() => ('#535355')};
  transition: color 0.3s;
  font-size: ${() => ('13px')};
  cursor: pointer;

  @media screen and (min-width: 768px) {
    &:not(:last-child) {
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      border-right: 1px solid #878787;
    }
  }


  &:hover {
    color: ${(props) => props.theme.activeColor};
    text-decoration: none;
  }
`;

export const Tnc = styled.span`
  color: '#535355';
`;
