import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { mediaQueries } from '../../utils/mediaQueries';

import LayoutContext from '../../context/LayoutContext';
import Link from '../Link';
import SNSLInk from './SNSLink';
import * as styles from './TopBar.module.scss';
import { trackingHelper } from '../../utils/tracking-helper';
import { trackingLinkHelper } from '../../utils/tracking-link-helper';

const useSnsData = () => {
  const { allSnsJson } = useStaticQuery(graphql`
    query {
      allSnsJson {
        edges {
          node {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 26, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            path
          }
        }
      }
    }
  `);
  return allSnsJson.edges;
};

const Container = styled.div`
  background-color: ${(props) => props.theme.headerBgColor};
  padding: 5px;
  border-bottom: 1px solid #ffffff;

  ${mediaQueries('md')} {
    padding: 5px 12px;
    border-bottom: none;
  }
`;

const TopBar = () => {
  const layoutProps = useContext(LayoutContext);
  const { gender } = layoutProps.pageContext;
  const { locale, isMobileView } = useSelector((state) => state.common);
  const { t,i18n } = useTranslation();
  const genderPath = gender === 'women' ? '/femme' : '';

  const snsData = useSnsData();

  let language = 'en';
  let originalPath = '/';

  try {
    language = locale === 'zh' ? 'en' : 'zh';
    originalPath = genderPath + layoutProps.pageContext.originalPath;
  } catch (error) {}

  const handleMenuClick = (item,link) => {
    //console.log(item)

    const topLevel = gender === 'men' ? t('mens_care') : t('women_care');

    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'menu_click',
      eventAction: 'select::header',
      eventCategory: 'main menu navigation',
      eventLabel: `${topLevel}::${item}`,
      breadcrumb: `${topLevel}::${item}`,      
      click_area: 'header',
      click_url: trackingLinkHelper(link),
      site_language: i18n.language,
      page_category: trackingHelper(window.location.pathname),
      brand: 'BIO',
      country: 'hk',
    }); 
  };

  const handleSNSClick = (link) => {
    let name = '';
    if(link.includes('facebook')){
      name = 'facebook';
    }else if(link.includes('instagram')){
      name = 'instagram';
    }else if(link.includes('youtube')){
      name = 'youtube';
    }else if(link.includes('wechat')){
      name = 'wechat';
    }
    window.dataLayer.push({
      event: 'uaevent',
      event_name: 'social_interaction',
      eventAction: 'visit',
      eventCategory: name,
      eventLabel: link,
      social_action: 'visit',
      social_network: name,
      brand: 'BIO',
      site_language: i18n.language,
      country: 'hk',
      page_category: trackingHelper(window.location.pathname),
   }); 
  };

  return (
    <>
      {!isMobileView ? (
        <Container className={`row no-gutters justify-content-between`}>
          <div>
            <Link className={styles.Link} to={`${genderPath}/store-locator`} onClick={() => {handleMenuClick(t('store_location'),"/store-locator")}}>
              {t('store_location')}
            </Link>
            <Link className={styles.Link} to={`${genderPath}/contact-us`} onClick={() => {handleMenuClick(t('contact_us'),"/contact-us")}}>
              {t('contact_us')}
            </Link>
            <Link className={styles.Link} to={originalPath} language={language} onClick={() => {handleMenuClick(t('header_locale'),originalPath)}}>
              {t('header_locale')}
            </Link>
          </div>
          <div className={`row no-gutters`}>
            {snsData.map(({ node }) => (
              <SNSLInk
                key={node.id}
                path={node.path}
                image={node.image.childImageSharp.fluid}
                onClick={() => {handleSNSClick(node.path)}}
              />
            ))}
          </div>
        </Container>
      ) : (
        <Container className={`row no-gutters justify-content-end`}>
          <Link className={styles.Link} to={originalPath} language={language}>
            {t('header_locale')}
          </Link>
        </Container>
      )}
    </>
  );
};

export default TopBar;
